import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./Link";
import IconButton from "./IconButton";
import SpaceBetween from "./layout/SpaceBetween";
import { css, withTheme } from "@delight-js/react";
import { Overline } from "./texts";

const SharingBar = withTheme(({ theme, url, ...props }) => {
  const {
    site: {
      siteMetadata: { sharing },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            sharing {
              name
              link
            }
          }
        }
      }
    `
  );
  return (
    <SpaceBetween
      css={css`
        text-align: center;
      `}
      {...props}
    >
      <Overline css={css`$mb2;`(theme)}>Sharing is Caring</Overline>
      {sharing
        .filter((s) => s.link)
        .map((s) => (
          <Link
            href={`${s.link}${escape(url)}?utm_source=${s.name}_WebsiteShare`}
            key={s.name}
            title={`Share on ${s.name}`}
            css={css`
              color: $color-spot1;
            `(theme)}
          >
            <IconButton
              type={s.name.toLowerCase()}
              css={css`
                max-width: 15vw;
                height: auto;
              `}
            />
          </Link>
        ))}
    </SpaceBetween>
  );
});

export default SharingBar;
