import * as React from "react";
import { css, withTheme } from "@delight-js/react";

const SpaceBetween = withTheme(({ theme, space = 1, ...props }) => (
  <div
    css={css`
      margin-top: -$space-2;
      margin-left: -$space-2;
      > * {
        margin-top: $space-2;
        margin-left: $space-2;
      }
    `(theme)}
    {...props}
  />
));
export default SpaceBetween;
