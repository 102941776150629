import { css, withTheme } from "@delight-js/react";
import * as React from "react";

const ActionBox = withTheme(({ theme, ...props }) => (
  <div
    css={css`
      position: relative;
      background-color: $color-bg2;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background-color: $color-spot2;

        @media:lg {
          width: 10px;
        }
      }
    `(theme)}
    {...props}
  />
));

export default ActionBox;
