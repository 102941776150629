import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Formik, Form, Field } from "formik";
import { css, withTheme } from "@delight-js/react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import ActionBox from "./ActionBox";
import Cell from "./layout/Cell";
import Grid from "./layout/Grid";
import Row from "./layout/Row";
import TextInputFormik from "./form/TextInputFormik";
import { Subtitle1, H3, H5, Overline } from "./texts";
import Button from "./Button";

const READY = 0,
  // PENDING = 1,
  SUCCESS = 2;

const NewsletterBlock = withTheme(({ theme, locale, ...props }) => {
  const {
    allTranslation: { edges },
  } = useStaticQuery(graphql`
    query NewsletterQuery {
      allTranslation(filter: { meta: { domain: { eq: "components" } } }) {
        edges {
          node {
            meta {
              language
            }
            data {
              newsletter {
                overline
                headline
                text
                label
                button
                thanks
              }
            }
          }
        }
      }
    }
  `);
  const { overline, headline, text, label, button, thanks } =
    edges?.find((edge) => edge.node.meta.language === locale)?.node?.data
      ?.newsletter || {};
  const [formState, setFormState] = React.useState(READY);
  async function handleFormSubmit({ phone, ...values }, { setSubmitting }) {
    setSubmitting(true);
    const response = await addToMailchimp(values.email);
    setFormState(response.result === "success" ? SUCCESS : READY);
  }
  return (
    <Formik
      initialValues={{
        email: "",
        acceptProcessing: false,
      }}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        ...formikProps
        /* and other goodies */
      }) => (
        <Grid {...props}>
          <Row>
            <Cell offset={{ default: 1 / 12 }} span={{ default: 10 / 12 }}>
              <ActionBox
                css={css`
                  position: relative;
                `}
              >
                <Row
                  css={css`$py10;`(theme)}
                  style={{
                    transition: "opacity .3s",
                    opacity: formState === SUCCESS ? 0 : 1,
                  }}
                >
                  <Cell offset={{ default: 1 / 10 }} span={{ default: 8 / 10 }}>
                    <Overline as="h2">{overline}</Overline>
                    <H3 css={css`$my1;`(theme)}>{headline}</H3>
                    <Subtitle1 as="p">{text}</Subtitle1>
                  </Cell>
                  <Cell offset={{ default: 1 / 10 }} span={{ default: 8 / 10 }}>
                    <Form
                      autoComplete="off"
                      css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;
                        @media:lg {
                          flex-direction: row;
                          align-items: flex-end;
                        }
                      `(theme)}
                    >
                      <Field
                        component={TextInputFormik}
                        label={label}
                        name="email"
                        type="email"
                        required="required"
                        css={css`
                          @media:lg {
                            flex-grow: 1;
                          }
                        `(theme)}
                      />
                      <Button
                        type="submit"
                        css={css`
                          margin-top: $space-4;
                          @media:lg {
                            margin: $space-4 0 0 $space-4;
                          }
                        `(theme)}
                        disabled={isSubmitting}
                      >
                        {button}
                      </Button>
                    </Form>
                  </Cell>
                </Row>
                {formState === SUCCESS && (
                  <div
                    css={css`
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <H5>{thanks}</H5>
                  </div>
                )}
              </ActionBox>
            </Cell>
          </Row>
        </Grid>
      )}
    </Formik>
  );
});

export default NewsletterBlock;
